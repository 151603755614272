

/* highlight on drag */
*::selection {
    background: rgba(245, 208, 254, 1)
}
*::-moz-selection {
  background: rgba(245, 208, 254, 1)
}
*::-webkit-selection {
    background: rgba(245, 208, 254, 1)
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* toggle checkbox  */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3b82f6;
}


/* scroll to top  */
#scroll-top {
  animation: fadeIn 0.3s;
  transition: opacity 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* animated underline  */

.ani-under {
  position: relative;
  text-decoration: none;
  width: fit-content;
}

.ani-under:hover {
  /* color: #000; */
  color: #e879f9;
}


.ani-under::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  /* background-color: #000; */
  background-color: #e879f9;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  /* animate from left  */
   transform-origin: top left;
}


.ani-under:hover::before {
  transform: scaleX(1);
}


/* truncate book author  */

.trunc-3lines {
 display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
  overflow: hidden;
}

.trunc-3 {
  -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

/* book loader 
Based on https://dribbble.com/shots/7425055-Book-Loader
*/
.book-loader {
  --book-bg: linear-gradient(135deg, #e879f9, #3b82f6);
  --book-shadow: rgba(232, 121, 249, 0.28);
  --book-text: #6C7486;
  --book-page-color: rgba(255, 255, 255, 0.36);
  --book-page-fold: rgba(255, 255, 255, 0.52);
  --book-ani-duration: 3s;
  width: 200px;
  height: 140px;
  position: relative;
  margin: auto;
}
.book-loader:before, .book-loader:after {
  --r: -6deg;
  content: "";
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  box-shadow: 0 16px 12px var(--book-shadow);
  transform: rotate(var(--r));
}
.book-loader:before {
  left: 4px;
}
.book-loader:after {
  --r: 6deg;
  right: 4px;
}
.book-loader div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--book-shadow);
  background-image: var(--book-bg);
}
.book-loader div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.book-loader div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--book-page-color);
  position: absolute;
  top: 10px;
  left: 10px;
  transform-origin: 100% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--book-ani-duration) ease infinite;
          animation: var(--book-ani-duration) ease infinite;
}
.book-loader div ul li:nth-child(2) {
  --c: var(--book-page-fold);
  -webkit-animation-name: page-2;
          animation-name: page-2;
}
.book-loader div ul li:nth-child(3) {
  --c: var(--book-page-fold);
  -webkit-animation-name: page-3;
          animation-name: page-3;
}
.book-loader div ul li:nth-child(4) {
  --c: var(--book-page-fold);
  -webkit-animation-name: page-4;
          animation-name: page-4;
}
.book-loader div ul li:nth-child(5) {
  --c: var(--book-page-fold);
  -webkit-animation-name: page-5;
          animation-name: page-5;
}
.book-loader div ul li svg {
  width: 90px;
  height: 120px;
  display: block;
}
.book-loader div ul li:first-child {
  --r: 0deg;
  --o: 1;
}
.book-loader div ul li:last-child {
  --o: 1;
}
.book-loader span {
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--book-text);
}

@-webkit-keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%, 100% {
    opacity: 0;
  }
  50%, 100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%, 100% {
    opacity: 0;
  }
  50%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
  65%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
  65%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%, 100% {
    opacity: 0;
  }
  80%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%, 100% {
    opacity: 0;
  }
  80%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0;
  }
  95%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0;
  }
  95%, 100% {
    transform: rotateY(0deg);
  }
}

/* book loader 2
Based on https://codepen.io/aaroniker/pen/zYOewEP
*/
.book-loading-indicator {
  --color: #e879f9;
  --duration: 6.8s;
  width: 32px;
  height: 12px;
  position: relative;
  margin: auto;
  zoom: 1.5;
}
.book-loading-indicator .inner {
  width: 32px;
  height: 12px;
  position: relative;
  transform-origin: 2px 2px;
  transform: rotateZ(-90deg);
  -webkit-animation: book var(--duration) ease infinite;
          animation: book var(--duration) ease infinite;
}
.book-loading-indicator .inner .left,
.book-loading-indicator .inner .right {
  width: 60px;
  height: 4px;
  top: 0;
  border-radius: 2px;
  background: var(--color);
  position: absolute;
}
.book-loading-indicator .inner .left:before,
.book-loading-indicator .inner .right:before {
  content: "";
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: inherit;
  position: absolute;
  top: -10px;
  left: 6px;
}
.book-loading-indicator .inner .left {
  right: 28px;
  transform-origin: 58px 2px;
  transform: rotateZ(90deg);
  -webkit-animation: left var(--duration) ease infinite;
          animation: left var(--duration) ease infinite;
}
.book-loading-indicator .inner .right {
  left: 28px;
  transform-origin: 2px 2px;
  transform: rotateZ(-90deg);
  -webkit-animation: right var(--duration) ease infinite;
          animation: right var(--duration) ease infinite;
}
.book-loading-indicator .inner .middle {
  width: 32px;
  height: 12px;
  border: 4px solid var(--color);
  border-top: 0;
  border-radius: 0 0 9px 9px;
  transform: translateY(2px);
}
.book-loading-indicator ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 50%;
  top: 0;
}
.book-loading-indicator ul li {
  height: 4px;
  border-radius: 2px;
  transform-origin: 100% 2px;
  width: 48px;
  right: 0;
  top: -10px;
  position: absolute;
  background: var(--color);
  transform: rotateZ(0deg) translateX(-18px);
  -webkit-animation-duration: var(--duration);
          animation-duration: var(--duration);
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.book-loading-indicator ul li:nth-child(0) {
  -webkit-animation-name: page-0;
          animation-name: page-0;
}
.book-loading-indicator ul li:nth-child(1) {
  -webkit-animation-name: page-1;
          animation-name: page-1;
}
.book-loading-indicator ul li:nth-child(2) {
  -webkit-animation-name: page-2;
          animation-name: page-2;
}
.book-loading-indicator ul li:nth-child(3) {
  -webkit-animation-name: page-3;
          animation-name: page-3;
}
.book-loading-indicator ul li:nth-child(4) {
  -webkit-animation-name: page-4;
          animation-name: page-4;
}
.book-loading-indicator ul li:nth-child(5) {
  -webkit-animation-name: page-5;
          animation-name: page-5;
}
.book-loading-indicator ul li:nth-child(6) {
  -webkit-animation-name: page-6;
          animation-name: page-6;
}
.book-loading-indicator ul li:nth-child(7) {
  -webkit-animation-name: page-7;
          animation-name: page-7;
}
.book-loading-indicator ul li:nth-child(8) {
  -webkit-animation-name: page-8;
          animation-name: page-8;
}
.book-loading-indicator ul li:nth-child(9) {
  -webkit-animation-name: page-9;
          animation-name: page-9;
}
.book-loading-indicator ul li:nth-child(10) {
  -webkit-animation-name: page-10;
          animation-name: page-10;
}
.book-loading-indicator ul li:nth-child(11) {
  -webkit-animation-name: page-11;
          animation-name: page-11;
}
.book-loading-indicator ul li:nth-child(12) {
  -webkit-animation-name: page-12;
          animation-name: page-12;
}
.book-loading-indicator ul li:nth-child(13) {
  -webkit-animation-name: page-13;
          animation-name: page-13;
}
.book-loading-indicator ul li:nth-child(14) {
  -webkit-animation-name: page-14;
          animation-name: page-14;
}
.book-loading-indicator ul li:nth-child(15) {
  -webkit-animation-name: page-15;
          animation-name: page-15;
}
.book-loading-indicator ul li:nth-child(16) {
  -webkit-animation-name: page-16;
          animation-name: page-16;
}
.book-loading-indicator ul li:nth-child(17) {
  -webkit-animation-name: page-17;
          animation-name: page-17;
}
.book-loading-indicator ul li:nth-child(18) {
  -webkit-animation-name: page-18;
          animation-name: page-18;
}

@-webkit-keyframes page-0 {
  4% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  13%, 54% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  63% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}

@keyframes page-0 {
  4% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  13%, 54% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  63% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-1 {
  5.86% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  14.74%, 55.86% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  64.74% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-1 {
  5.86% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  14.74%, 55.86% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  64.74% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-2 {
  7.72% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  16.48%, 57.72% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  66.48% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-2 {
  7.72% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  16.48%, 57.72% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  66.48% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-3 {
  9.58% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  18.22%, 59.58% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  68.22% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-3 {
  9.58% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  18.22%, 59.58% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  68.22% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-4 {
  11.44% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  19.96%, 61.44% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  69.96% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-4 {
  11.44% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  19.96%, 61.44% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  69.96% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-5 {
  13.3% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  21.7%, 63.3% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  71.7% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-5 {
  13.3% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  21.7%, 63.3% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  71.7% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-6 {
  15.16% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  23.44%, 65.16% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  73.44% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-6 {
  15.16% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  23.44%, 65.16% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  73.44% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-7 {
  17.02% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  25.18%, 67.02% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  75.18% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-7 {
  17.02% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  25.18%, 67.02% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  75.18% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-8 {
  18.88% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  26.92%, 68.88% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  76.92% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-8 {
  18.88% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  26.92%, 68.88% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  76.92% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-9 {
  20.74% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  28.66%, 70.74% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  78.66% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-9 {
  20.74% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  28.66%, 70.74% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  78.66% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-10 {
  22.6% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  30.4%, 72.6% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  80.4% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-10 {
  22.6% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  30.4%, 72.6% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  80.4% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-11 {
  24.46% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  32.14%, 74.46% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  82.14% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-11 {
  24.46% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  32.14%, 74.46% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  82.14% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-12 {
  26.32% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  33.88%, 76.32% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  83.88% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-12 {
  26.32% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  33.88%, 76.32% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  83.88% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-13 {
  28.18% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  35.62%, 78.18% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  85.62% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-13 {
  28.18% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  35.62%, 78.18% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  85.62% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-14 {
  30.04% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  37.36%, 80.04% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  87.36% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-14 {
  30.04% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  37.36%, 80.04% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  87.36% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-15 {
  31.9% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  39.1%, 81.9% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  89.1% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-15 {
  31.9% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  39.1%, 81.9% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  89.1% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-16 {
  33.76% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  40.84%, 83.76% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  90.84% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-16 {
  33.76% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  40.84%, 83.76% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  90.84% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-17 {
  35.62% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  42.58%, 85.62% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  92.58% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-17 {
  35.62% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  42.58%, 85.62% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  92.58% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes page-18 {
  37.48% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  44.32%, 87.48% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  94.32% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@keyframes page-18 {
  37.48% {
    transform: rotateZ(0deg) translateX(-18px);
  }
  44.32%, 87.48% {
    transform: rotateZ(180deg) translateX(-18px);
  }
  94.32% {
    transform: rotateZ(0deg) translateX(-18px);
  }
}
@-webkit-keyframes left {
  4% {
    transform: rotateZ(90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
  }
  46%, 54% {
    transform: rotateZ(90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(90deg);
  }
}
@keyframes left {
  4% {
    transform: rotateZ(90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
  }
  46%, 54% {
    transform: rotateZ(90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(90deg);
  }
}
@-webkit-keyframes right {
  4% {
    transform: rotateZ(-90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
  }
  46%, 54% {
    transform: rotateZ(-90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(-90deg);
  }
}
@keyframes right {
  4% {
    transform: rotateZ(-90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
  }
  46%, 54% {
    transform: rotateZ(-90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(-90deg);
  }
}
@-webkit-keyframes book {
  4% {
    transform: rotateZ(-90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  40.01%, 59.99% {
    transform-origin: 30px 2px;
  }
  46%, 54% {
    transform: rotateZ(90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  96% {
    transform: rotateZ(-90deg);
  }
}
@keyframes book {
  4% {
    transform: rotateZ(-90deg);
  }
  10%, 40% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  40.01%, 59.99% {
    transform-origin: 30px 2px;
  }
  46%, 54% {
    transform: rotateZ(90deg);
  }
  60%, 90% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  96% {
    transform: rotateZ(-90deg);
  }
}
